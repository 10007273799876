import { Component, OnInit } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

/* iOS cache reload test codes and iOS banner to install */
import { get, set } from 'idb-keyval';
import { ToastController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { MenuPage } from './menu/menu.page';

import { AuthService } from './services/auth.service';
// import { FCM } from '@ionic-native/fcm/ngx';
/* fcm push notifications */

// import { FcmService } from './services/fcm.service';

/*******/
import * as firebase from 'firebase';

import { firebaseConfig } from '../environments/environment';

/* iOS cache reload test codes and iOS banner to install */

import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { isNull } from 'util';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { OneSignal } from '@ionic-native/onesignal/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public appMenu = [

  ];

  constructor(
    private platform: Platform,
    public authservice: AuthService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastController: ToastController,
    private swUpdate: SwUpdate,
    private alertController: AlertController,
    // public fcm: FcmService,
    private oneSignal: OneSignal,
    // public fcm2: FCM,
    private browser1: InAppBrowser,
    private db: AngularFirestore
  ) {
    this.initializeApp();
  }

  public Webpage1(url: string) {
    // const url = 'https://intimausa.com';
    const options: InAppBrowserOptions = {
      toolbarcolor: '#3880ff',
      hideurlbar: 'no',
      closebuttoncolor: '#ffffff',
      navigationbuttoncolor: '#ffffff',
      location : 'no', // Or 'no'
      hidden : 'no', // Or  'yes'
      clearcache : 'yes',
      clearsessioncache : 'yes',
      zoom : 'yes', // Android only ,shows browser zoom controls
      hardwareback : 'yes',
      mediaPlaybackRequiresUserAction : 'no',
      shouldPauseOnSuspend : 'no', // Android only
      closebuttoncaption : 'INICIO', // iOS only
      disallowoverscroll : 'no', // iOS only
      toolbar : 'yes', // iOS only
      enableViewportScale : 'yes', // iOS only
      allowInlineMediaPlayback : 'no', // iOS only
      presentationstyle : 'fullscreen', // iOS only
      lefttoright : 'no', // iOS only
      fullscreen : 'yes', // Windows only
      usewkwebview : 'no' // iOS only
    };
    this.browser1.create(url, '_blank', options);

  }


  Onlogout() {
    this.authservice.logout();
    console.log('logout clicked');
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      /* this.statusBar.styleLightContent();*/
      this.splashScreen.hide();

      // this.showIosInstallBanner();

      // this.checkUpdate();

    //   this.fcm.getPermissionNative();
    let data: any = localStorage.getItem('push_data');
    if (data) {
      localStorage.removeItem('push_data');
      data = JSON.parse(data);
      this.Webpage1(data.website);
    }

      this.oneSignal.startInit('28c007c5-a0fa-4b54-a20a-b41131e15379', '55771147815');

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

        this.oneSignal.handleNotificationReceived().subscribe(() => {
        // do something when notification is received
        });

        this.oneSignal.handleNotificationOpened().subscribe((result) => {
            let data = result.notification.payload.additionalData
           // if (result.notification.isAppInFocus) {
                console.log('Received in background');
                localStorage.setItem('push_data', JSON.stringify(data));
                //
                //alert(JSON.stringify(data));
                // const ate = JSON.stringify(data.targetUrl);
                //
                this.Webpage1(data.website);
        /*    } else {
                // alert(data.title + '\n' + data.body);
                //alert(JSON.stringify(data));
                //  this.Webpage1(data.click_action);
                this.presentAlert1(data);
            }*/
        });

        this.oneSignal.endInit();
    //   this.fcm.getPermission().subscribe();

    //   this.fcm2.getToken().then(token => {
    //     // backend.registerToken(token);
    //     console.log(token);
    //   });

        //   this.fcm.listenToMessages().subscribe();

    //   let data: any = localStorage.getItem('push_data');
    //   if (data) {
    //     localStorage.removeItem('push_data');
    //     data = JSON.parse(data);
    //     this.Webpage1(data.website);
    //   }

    //   this.fcm2.subscribeToTopic('notificaciones');

    //   this.fcm2.onNotification().subscribe(data => {
    //     // alert(JSON.stringify(data));
    //     if (data.wasTapped) {
    //       console.log('Received in background');
    //       localStorage.setItem('push_data', JSON.stringify(data));
    //       //
    //       //alert(JSON.stringify(data));
    //       // const ate = JSON.stringify(data.targetUrl);
    //       //
    //       this.Webpage1(data.website);
    //     } else {
    //       // alert(data.title + '\n' + data.body);
    //       //alert(JSON.stringify(data));
    //       //  this.Webpage1(data.click_action);
    //       this.presentAlert1(data);
    //     }
    //   });

    });
  }

  async presentAlert1(data) {
    const alert = await this.alertController.create({
      header: 'New Notification',
      subHeader: data.title,
      message: data.body + '\n' + data.website,
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Open link',
        handler: () => {
          this.Webpage1(data.website);
        }
      }]
    });

    await alert.present();
  }

  async showIosInstallBanner() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };  // Detects if device is in standalone mode
    // tslint:disable-next-line:no-string-literal
    const isInStandaloneMode = () => ('standalone' in window.navigator) && window.navigator['standalone'];

    // Show the banner once
    const isBannerShown = await get('isBannerShown');

    // Checks if it should display install popup notification
    if (isIos() && !isInStandaloneMode() && isBannerShown === undefined) {
      const toast = await this.toastController.create({
        showCloseButton: true,
        closeButtonText: 'OK',
        cssClass: 'custom-toast',
        position: 'bottom',
        /*message: `To install the app, tap "Share" icon below and select "Add to Home Screen".`,*/
        message: `Para instalar la App, toque el icono "Compartir" y seleccione "Agregar a inicio".`,
      });
      toast.present();
      set('isBannerShown', true);
    }
  }

  checkUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(async () => {
        const alert = await this.alertController.create({
          header: `Intima Actualización!`,
          /*message: `Newer version of the app is available. It's a quick refresh away!`,*/
          message: `Una nueva versión del App está disponible. Toque Refresh para actualizarlo.`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Refresh',
              handler: () => {
                window.location.reload();
                console.log('refresh');
              },
            },
          ],
        });
        await alert.present();
      });
    }
  }

  ngOnInit() {
    this.initializeApp();
  }


}
