import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from '../shared/user.class';
import { auth } from 'firebase/app';
/*  import { auth } from 'firebase'; */

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLogged: any = false;

  constructor(public AFauth: AngularFireAuth, private router: Router) {
    AFauth.authState.subscribe(user => (this.isLogged = user));
  }

  login(email: string, password: string) {

    return new Promise((resolve, rejected) => {
      this.AFauth.auth.signInWithEmailAndPassword(email, password).then(user => {
        resolve(user);
        this.isLogged = true;
      }).catch(err => rejected(err));
    });

  }

  logout() {
    this.AFauth.auth.signOut().then(() => {
      this.router.navigate(['/']);
      this.isLogged = false;
    });

  }

/*dominicode version */

// login

async onLogin( user: User ) {
  try {
    return await this.AFauth.auth.signInWithEmailAndPassword(
      user.email,
      user.password
    );
  } catch (error) {
    console.log('Error on login user', error);
  }
}

// register
async onRegister( user: User ) {
  try {
    return await this.AFauth.auth.createUserWithEmailAndPassword(
      user.email,
      user.password
    );
  } catch (error) {
    console.log('Error on login', error);
  }
}

// facebook
async onFacebook( ) {
  this.AFauth.auth.signInWithPopup(new auth.FacebookAuthProvider());
}

// Google
async onGoogle() {
  this.AFauth.auth.signInWithPopup(new auth.GoogleAuthProvider());
}

}
